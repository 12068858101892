import React from "react";
import HarmonizedWeb from "../../../images/LandingPage/Contents/Harmonized/Harmonized.webm";
import HarmonizedMp from "../../../images/LandingPage/Contents/Harmonized/Harmonized.mp4";

const HarmonizeComponent = () => {
  return (
    <>
      <video
        loading="lazy"
        autoPlay
        muted
        loop
        playsInline
        style={{
          justifySelf: "center",
          maxWidth: 400,
          width: "100%",
          height: "auto",
          borderRadius: 4,
        }}
      >
        <source src={HarmonizedWeb} type="video/webm" />
        <source src={HarmonizedMp} type="video/mp4" />
      </video>
    </>
  );
};

export default HarmonizeComponent;
