import React from "react";
import { useTranslation, Trans, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component";
import CommonLayout from "../../components/layout/CommonLayout";
import SEO from "../../components/seo";
import Thumbnail from "../../images/LandingPage/Contents/ThumbnailContent.jpg";
import Banner from "../../components/LandingPage/Banner/index";
import SellingContainer from "../../components/LandingPage/OnlineSelling/SellingContainer";
import WebKuu from "../../components/LandingPage/OnlineSelling/WebKuu";
// import Register from "../../components/LandingPage/OnlineSelling/Register";
import FirstContentProcess from "../../components/LandingPage/Contents/FirstContentProcess";
import SecondContentProcess from "../../components/LandingPage/Contents/SecondContentProcess";
import RemoveBgComponent from "../../components/LandingPage/Contents/RemoveBg";
import ThirdContentProcess from "../../components/LandingPage/Contents/ThirdContentProcess";
import ElementComponent from "../../components/LandingPage/Contents/ElementComponent";
import HarmonizeComponent from "../../components/LandingPage/Contents/HarmonizeComponent";
import ThirdComponent from "../../components/LandingPage/OnlineSelling/ThirdComponent";

const SocialIcon = loadable(() =>
  import("../../components/LandingPage/SocialIcon")
);

const getImages = (images, currentLanguage) => {
  return {
    edges: images.edges.filter((imageFile) =>
      imageFile.node.relativeDirectory.includes(`${currentLanguage}`)
    ),
  };
};

const Contents = ({
  data: { thirdSlideContent },
  location: { pathname },
  path,
}) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <CommonLayout>
      <SEO title="Content" pathname={pathname} metaImage={Thumbnail} />
      <Banner
        imageComponent={
          <StaticImage
            placeholder="tracedSVG"
            quality="70"
            src="../../images/LandingPage/OnlineSelling/Cover/ContentBanner.png"
            alt="ContentBanner"
          />
        }
        paragraph={
          <>
            <h2 className="subTitle-bold-only">
              {t("landing.content.banner.Create any desired visual content")}
            </h2>
            <h2 className="h2-subTitle">
              <Trans components={[<br />]}>
                {t("landing.content.banner.To grasp audience's")}
              </Trans>
            </h2>
            <h2 className="subTitle-bold-only ">
              {t("landing.content.banner.Fast & easy")}
            </h2>
            <h3>
              <Trans
                components={[
                  <br />,
                  <strong className="bold-color" />,
                  <br className="h3-br-display-mobile" />,
                  <br className="h3-br-display" />,
                  <strong className="br-display" />,
                  <br className="h3-br-display-ipad" />,
                ]}
              >
                {t("landing.content.banner.Did you know")}
              </Trans>
            </h3>
          </>
        }
      />
      <section>
        <SellingContainer
          imageContent={<FirstContentProcess />}
          paragraph={
            <>
              <h1>
                <Trans components={[<br />, <strong className="bold-color" />]}>
                  {t("landing.content.01.topic")}
                </Trans>
              </h1>

              <div className="number">01</div>
              <h2
                style={{
                  fontSize: "clamp(1.1rem, 2.5vw, 1.2rem)",
                }}
              >
                <Trans components={[<br />]}>
                  {t("landing.content.01.sub-01")}
                </Trans>
              </h2>
            </>
          }
        />
      </section>
      <section>
        <SellingContainer
          reverseOrder={true}
          imageContent={<RemoveBgComponent />}
          paragraph={
            <>
              <h1>
                <Trans
                  components={[
                    <br />,
                    <strong className="medium-bold" />,
                    <strong className="bold-color" />,
                  ]}
                >
                  {t("landing.content.remove.topic")}
                </Trans>
              </h1>
            </>
          }
        />
      </section>

      <section>
        <SellingContainer
          styledText={{
            background: "#fff",
            borderRadius: 4,
            height: "85% !important",
          }}
          styledBackground={{
            content: "' '",
            background: "#3B75A4",
            clipPath: "polygon(0 0%, 90% 0, 100% 100%, 0% 100%)",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
            transform: "translate(-65%, 0px)",
          }}
          imageContent={<SecondContentProcess />}
          paragraph={
            <div
              style={{
                margin: "1em 0em",
              }}
            >
              <div className="number">02</div>
              <h1
                className="medium-bold"
                style={{
                  fontSize: "clamp(1.1rem, 2.5vw, 1.2rem)",
                }}
              >
                <Trans
                  components={[
                    <br />,
                    <strong className="bold-color" />,
                    <strong />,
                    <br className="br-disable-display-ipad" />,
                  ]}
                >
                  {t("landing.content.02.topic")}
                </Trans>
              </h1>
            </div>
          }
        />
      </section>
      <section>
        <SellingContainer
          reverseOrder={true}
          imageContent={<ThirdContentProcess />}
          paragraph={
            <>
              <div className="number">03</div>
              <h1
                className="medium-bold"
                style={{
                  fontSize: "clamp(1.1rem, 2.5vw, 1.2rem)",
                }}
              >
                <Trans
                  components={[
                    <strong />,
                    <strong className="bold-color br-word" />,
                    <br className="br-disable-display-ipad" />,
                  ]}
                >
                  {t("landing.content.03.topic")}
                </Trans>
              </h1>
            </>
          }
        />
      </section>

      <section>
        <SellingContainer
          className="firstComponent"
          imageContent={<ElementComponent />}
          paragraph={
            <>
              <h1 className="medium-bold">
                <Trans components={[<br />, <strong className="bold-color" />]}>
                  {t("landing.content.elements.topic")}
                </Trans>
              </h1>
            </>
          }
        />
      </section>
      <section>
        <SellingContainer
          reverseOrder={true}
          imageContent={<HarmonizeComponent />}
          paragraph={
            <>
              <h1 className="medium-bold">
                <Trans
                  components={[
                    <br />,
                    <strong className="bold-color" />,
                    <strong className="medium-bold" />,
                    <br className="br-display-iphone" />,
                  ]}
                >
                  {t("landing.content.harmonization.topic")}
                </Trans>
              </h1>
            </>
          }
        />
      </section>
      <section>
        <SellingContainer
          styledText={{ padding: "0.5em 0.8em !important" }}
          paragraph={
            <>
              <h1 className="medium-bold">
                <Trans
                  components={[
                    <br />,
                    <strong className="medium-bold" />,
                    <strong />,
                  ]}
                >
                  {t("landing.content.eye-catching.topic")}
                </Trans>
              </h1>

              <div className="wrap-icon">
                <SocialIcon />
              </div>
            </>
          }
          imageContent={
            <ThirdComponent
              imageObject={getImages(thirdSlideContent, language)}
            />
          }
        />
      </section>

      <section>
        <SellingContainer
          reverseOrder={true}
          styledBackground={{
            content: "' '",
            background: "#f3f3f3",
            position: "absolute",
            width: "100%",
            borderRadius: "40% / 100% 0% 0% 100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
            transform: "translate(70%, 0)",
          }}
          imageContent={
            <StaticImage
              loading="lazy"
              style={{ margin: "0 auto" }}
              placeholder="tracedSVG"
              quality="80"
              src="../../images/LandingPage/Contents/ContentResize.png"
              alt="ContentResize"
              width={500}
            />
          }
          paragraph={
            <>
              <h1 className="medium-bold">
                <Trans
                  components={[
                    <br />,
                    <strong className="bold-color" />,
                    <strong className="medium-bold" />,
                  ]}
                >
                  {t("landing.content.resize.topic")}
                </Trans>
              </h1>
            </>
          }
        />
      </section>

      <section>
        <WebKuu
          paragraph={
            <>
              <h1>
                <Trans
                  components={[
                    <br />,
                    <strong />,
                    <strong className="highlight" />,
                  ]}
                >
                  {t("landing.content.webKuuContent.topic")}
                </Trans>
              </h1>
              <h2>{t("landing.content.webKuuContent.sub")}</h2>
              <a href="/">www.KUUpixmaker.ai</a>
            </>
          }
        />
      </section>
      {/* <section id="register">
        <Register
          pathName={path.replace("content/", "")}
          paramName={"content"}
        />
      </section> */}
    </CommonLayout>
  );
};

export default Contents;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    thirdSlideContent: allFile(
      filter: { relativePath: { glob: "LandingPage/Contents/Slide/**/*.png" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          base
          id
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              quality: 80
              blurredOptions: { width: 100 }
            )
          }
        }
      }
    }
  }
`;
