import React from "react";
import SecondContentWeb from "../../../images/LandingPage/Contents/Process2/Content-2.webm";
import SecondContentMp from "../../../images/LandingPage/Contents/Process2/Content-2.mp4";

const SecondContentProcess = () => {
  return (
    <>
      <video
        loading="lazy"
        autoPlay
        muted
        loop
        playsInline
        style={{
          justifySelf: "center",
          maxWidth: 400,
          width: "100%",
          height: "auto",
          borderRadius: 4,
        }}
      >
        <source src={SecondContentWeb} type="video/webm" />
        <source src={SecondContentMp} type="video/mp4" />
      </video>
    </>
  );
};

export default SecondContentProcess;
