import React from "react";
import RemoveBgWeb from "../../../images/LandingPage/Contents/RemoveBg/Remove-Background.webm";
import RemoveBgMP from "../../../images/LandingPage/Contents/RemoveBg/Remove-Background.mp4";

const RemoveBgComponent = () => {
  return (
    <>
      <video
        loading="lazy"
        autoPlay
        muted
        loop
        playsInline
        style={{
          justifySelf: "center",
          maxWidth: 400,
          width: "100%",
          height: "auto",
          borderRadius: 4,
        }}
      >
        <source src={RemoveBgWeb} type="video/webm" />
        <source src={RemoveBgMP} type="video/mp4" />
      </video>
    </>
  );
};

export default RemoveBgComponent;
