import React from "react";
import ElementsWeb from "../../../images/LandingPage/Contents/Element/Elements.webm";
import ElementsMp from "../../../images/LandingPage/Contents/Element/Elements.mp4";

const ElementComponent = () => {
  return (
    <>
      <video
        loading="lazy"
        autoPlay
        muted
        loop
        playsInline
        style={{
          justifySelf: "center",
          maxWidth: 400,
          width: "100%",
          height: "auto",
        }}
      >
        <source src={ElementsWeb} type="video/webm" />
        <source src={ElementsMp} type="video/mp4" />
      </video>
    </>
  );
};

export default ElementComponent;
